//app.scssにインポートさせてビルドする。
//@import 'hum-animation'; これをapp.cssに追加

//ボタンのサイズ調整
$button-size:40px;

//横線の上下の近さの調整
$button-bar-positions:8px;

header{
  position: fixed;
  width: 100%;
  z-index: 5;
  transition: all 0.5s ease-out;
  
}


////////////////////////////////////////////////////////////////
//オプション　SNSアイコンを設置する場合
////////////////////////////////////////////////////////////////
$sns-button-size:30px;

.linkicon {
  position: absolute;
  height: $button-size;
  top: 50%;
  /*ハンバーガメニューの幅だけずらす*/
  right: calc($button-size + 15px);
  // z-index: 1;
  width: calc(($sns-button-size * 2) + 10px);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  img {
    width: $sns-button-size;
    height: $sns-button-size;
    transition: .5s;
      &:hover {
        transform: rotate(360deg);
      }
  }

}

@media (min-width: 640px) {
  .top-bar-right {
    /*ハンバーガーメニュー+リンクアイコンの幅*/
    margin-right: calc($button-size + ($sns-button-size * 2) + 40px );
  }
  .top-bar-left{
    margin-bottom: 15px;
  }
}

@media (max-width: 640px) {
  .top-bar-right {
   display: flex;
   justify-content: center;
  }

  .top-bar-left {
    margin-bottom: 15px;
  }
}
////////////////////////////////////////////////////////////////
//オプションここまで
////////////////////////////////////////////////////////////////


//ボタンのカバー
.outer-menu {
  position: absolute;
  top: 10px;
  right: -10px;
  width:$button-size;//子要素と同じ値に設定
  z-index: 1;

  * {
      box-sizing: border-box;
      font-family: 'Open Sans', sans-serif;
      font-weight: 300;
    }
        a,
        a:visited,
        a:hover,
        a:active {
          color: inherit;
          text-decoration: none;
        }

  .checkbox-toggle {
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 2;
    cursor: pointer;
    width: $button-size;
    height: $button-size;
    opacity: 0;

    &:checked+.hamburger>div {
      transform: rotate(135deg);
    }

    &:checked+.hamburger>div:before,
    &:checked+.hamburger>div:after {
      top: 0;
      transform: rotate(90deg);
    }

    &:checked+.hamburger>div:after {
      opacity: 0;
    }

    &:checked~.hamburger-menu {
      pointer-events: auto;
      visibility: visible;

      >div {
        transform: scale(1);
        transition-duration: 0.75s;

        >div {
          opacity: 1;
          transition: opacity 0.4s ease 0.4s;
        }
      }
    }

    &:hover+.hamburger {
      box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
    }

    &:checked:hover+.hamburger>div {
      transform: rotate(225deg);
    }
  }

//ボタンのレイアウト
  .hamburger {
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 1;
    width: $button-size;
    height: $button-size;
    padding: 0.5em 0.6em;
    background: #0071BC;//背景色
    border-radius:50%;
    cursor: pointer;
    transition: box-shadow 0.4s ease;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    >div {
      position: relative;
      flex: none;
      width: 100%;
      height: 2px;
      background: #FEFEFE;//バーの色
      transition: all 0.4s ease;
      display: flex;
      align-items: center;
      justify-content: center;

      &:before,
      &:after {
        content: '';
        position: absolute;
        z-index: 1;
        top: -$button-bar-positions;//上のバーの高さの位置
        left: 0;
        width: 100%;
        height: 2px;
        background: inherit;
        transition: all 0.4s ease;
      }

      &:after {
        top: $button-bar-positions;//下のバーの高さの位置
      }
    }
  }

//全域メニューのレイアウト
  .hamburger-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    visibility: hidden;
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    outline: 1px solid transparent;
    display: flex;
    align-items: center;
    justify-content: center;

    >div {
      width: 300vw;
      height: 300vw;
      color: #FEFEFE;
      background: #0071BC;
      border-radius: 50%;
      transition: all 0.4s ease;
      flex: none;
      transform: scale(0);
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;

      >div {
        text-align: center;
        max-width: 90vw;
        max-height: 100vh;
        opacity: 0;
        transition: opacity 0.4s ease;
        overflow-y: auto;
        flex: none;
        display: flex;
        align-items: center;
        justify-content: center;

        >ul {
          list-style: none;
          padding: 0 1em;
          margin: 0;
          display: block;
          max-height: 100vh;

          >li {
            padding: 0;
            margin: 1em;
            font-size: 24px;
            display: block;

            >a {
              position: relative;
              display: inline;
              cursor: pointer;
              transition: color 0.4s ease;

              &:hover {
                color: #e5e5e5;

                &:after {
                  width: 100%;
                }
              }

              &:after {
                content: '';
                position: absolute;
                z-index: 1;
                bottom: -0.15em;
                left: 0;
                width: 0;
                height: 2px;
                background: #e5e5e5;
                transition: width 0.4s ease;
              }
            }
          }
        }
      }
    }
  }
}