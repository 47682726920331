//トップページの画像の高さを調整
$top_backheight_sp:428px;
$top_backheight_pc: 530px;

$top_area_space_pc_gap:30px;

//下層ページの画像の高さを調整
$sub_backheight_sp:250px;
$sub_backheight_pc: 250px;

//画像ボタンの角丸　.img_button_cover
$img_button_radius: 10px; 

//全体のパディング
$wrapper-padding_sp:10PX 20px !important;
$wrapper-padding_pc: 0 50px !important;

.wrapper{
  padding:$wrapper-padding_sp;
  margin:0 auto;
  @media (min-width: 640px){
    padding:$wrapper-padding_pc;
  }
}


// クライアント説明
.message{
  max-width: 500px;
  margin:20px auto 50px;
  padding:10px;
}

.need_list{
  padding:20px 10px;
  border: 1px solid gray;
  border-radius: 10px;
  li{
    margin-bottom: 10px;
  }
  h4{
    color:rgb(0, 90, 194);
    font-weight: 600;
  }
}

//トップページの画像用
.top_background,
.sub_background{
  background-size: cover;
  width: 100%;
  position: absolute;
  top:0;
  left:0;
  z-index:-1;
}

.top_background {
  height: $top_backheight_sp;
}

// 背景用のfixedの要の分だけ高さを確保
.top_area_space {
  height: $top_backheight_sp;
}

.sub_background {
  height: $sub_backheight_sp;
}

.sub_area_space {
  height: $sub_backheight_sp;
}

.top_area_space,
.sub_area_space{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img{
    display: block;
    width:150px;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  p{
    color:white;
    font-weight: 600;
    text-align: center;
  }
  h1{
    margin-top:100px;
    color: white;
    font-weight: 600;
  }
}

@media (min-width: 640px) {
  .top_background {
    height: $top_backheight_pc;
  }

  .top_background {
    background-size: cover;
  }

  .top_area_space {
    height: calc($top_backheight_pc - $top_area_space_pc_gap);

    img {
      width: 200px;
    }
  }

  .top_area_space {
    img {
      width: 200px;
    }

  .sub_area_space {
      h1 {
        margin-top: 70px;
      }
    }
  }

  .sub_background {
    height: $sub_backheight_pc;
  }

  .sub_area_space {
    height: $sub_backheight_pc;
  }
}


.top-bar ul {
  background:none!important;
}

  /*--------------------------------
  出船情報
  ----------------------------------*/
  .Departure_info{
    max-width: 500px;
    color:white;
    text-align: center;
    margin: 10px auto 30px;
    border-radius: 5px;
    background-blend-mode: multiply;
    h3{
      font-weight: 600;
      font-size: 1.3rem;
      margin-bottom: 0;
      border: solid 0.5px #ffffff; 
      display: inline-block;
      padding:5px 30px;
      line-height: 1;
      min-width: 210px;
      border-radius: 5px;
      letter-spacing: 10px;
    }
  }
  
  .Departure{
    font-size: 1.2rem;
    margin:5px auto;
  }
  .area_box{
    background-color: white;
    color: #006492;
    padding:10px;
    border-radius: 5px;
    overflow: hidden;
    /* max-width: 350px; */
    display: inline-block;
    margin: 0 auto;
    font-size: 0.85rem;
  }
  @media (min-width: 650px){
    .Departure_info{
      margin: 10px auto 10px;
      h3{
        font-size: 1.5rem;
        padding:8px 30px;
      }
    }
    .Departure{
      font-size: 1.3rem;
    }
    .area_box{
      font-size: 1rem;
    }
  } 


//画像ボタン
//a要素に適用　中のimgを整える
.img_button_cover{
  overflow: hidden;
  border-radius: $img_button_radius;
  img{
    width: 100%;
  }
}

////////////////////////////////
//項目タイトル全体の装飾
////////////////////////////////
.title_wraaper {
  margin: 20px auto;
  img {
    padding: 0 5px
  }
  h2 {
    white-space: nowrap;
    font-size: 1.4rem;
    margin: 10px 0 0;
    font-weight: 600;
  }
  p {
    margin-bottom: 10px;
  }
  div:nth-of-type(1),
  div:nth-of-type(3) {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      background: #29ABE2;
      position: absolute;
      top: 50%;
      left: 0;
    }
  }

  div:nth-of-type(2) {
    text-align: center;
    color: #29ABE2;
  }

  div:nth-of-type(4) {
    border-bottom: #29ABE2 1px solid;
    margin: 0 0 20px;
  }
}

.list_text_size{
  time{font-size:0.7rem;}
  h3 {font-size:1.2rem ; font-weight: 600;}
  p{
    font-size:0.9rem ;
    line-clamp:3;
  }
}

////////////////////////////////
//島割の画像一覧
////////////////////////////////

.iso-pic {
  >div {
    overflow: hidden;
    border-radius: 5px;
    position: relative;

    >p {
      bottom: 5px;
      left: 5px;

      margin: 0;
      font-size: 1.3rem;
      padding: 0px 20px 0px 10px;
      position: absolute;
      text-align: left;
      border-radius: 5px 5px 5px 5px;
      background: white;
      font-weight: 600;
      color: #0084c2;
    }
  }
}