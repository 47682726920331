.footer {
  background: #88D3ED;
  padding: 40px 0 0;

  ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    padding: 20px 0 40px;

    li a {
      color: #0071BC;
      text-decoration: none;
      font-weight: 600;
      &:hover {
        text-decoration: underline;
      }
    }
  }

}

.copy {
  padding: 15px;
  background-color: white;
  text-align: center;
  color: #0071BC;
}